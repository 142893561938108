<template>
  <div id="app">
    <TheHeader />
    <div class="container-fluid">
      <router-view :templates="templates" @create-template="addTemplate" @update-template="updateTemplate" @cancel-edit="cancelEdit" />
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "App",
  components: {
    TheHeader,
  }
}
</script>

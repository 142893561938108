<template>
  <div class="container register">
    <h2 style="text-align: center;">Create Survival Kit for Ship Call</h2>
    <div class="row justify-content-center mt-5">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <img class="logo mb-4" alt="logo" src="@lib/frontend/assets/new.png">
            <form>
              <div class="form-group">
                <label for="dateInput">Date:</label>
                <input v-model="date" type="date" id="dateInput" class="form-control" required />
              </div>
              <div class="form-group">
                <label for="cruiseLine">Cruise Line:</label>
                <select id="cruiseLine" v-model="selectedCruiseLine" class="form-control">
                  <option v-for="cruiseLine in Object.keys(groupedTemplates)" :value="cruiseLine">
                    {{ cruiseLine }}
                  </option>
                </select>
              </div>

              <!-- Tour Name field -->
              <div class="form-group" v-if="selectedCruiseLine">
                <label for="tourName">Tour Name:</label>
                <select id="tourName" v-model="selectedTemplate" class="form-control">
                  <option v-for="template in groupedTemplates[selectedCruiseLine]" :value="template">
                    {{ template.attributes.tour_name }} (Ship is docked in {{ template.attributes.end_city }})
                  </option>
                </select>
              </div>

              <!-- Drop-off Location field -->
              <div class="form-group">
                <label for="dropOffLocation">Drop-off Location:</label>
                <select id="dropOffLocation" v-model="selectedDropOffLocation" class="form-control">
                  <option v-for="location in locations" :value="location">
                    {{ location.attributes.name }}
                  </option>
                </select>
              </div>

              <!-- Pick-up Location field -->
              <div class="form-group">
                <label for="pickUpLocation">Pick-up Location:</label>
                <select id="pickUpLocation" v-model="selectedPickUpLocation" class="form-control">
                  <option v-for="location in locations" :value="location">
                    {{ location.attributes.name }}
                  </option>
                </select>
              </div>

              <!-- Closest Underground Station field -->
              <div class="form-group">
                <label for="undergroundStation">Closest Public Train Station:</label>
                <select id="undergroundStation" v-model="selectedUndergroundStation" class="form-control">
                  <option v-for="location in locations" :value="location">
                    {{ location.attributes.name }}
                  </option>
                </select>
              </div>

              <!-- Emergency -->
              <div class="form-group">
                <label for="emergencyContact">Emergency Contact:</label>
                <input id="emergencyContact" v-model="emergency_tc" type="text" class="form-control"
                  placeholder="+49XXXXXXXXXXX" />
              </div>

              <!-- Meeting Time field -->
              <div class="form-group">
                <label for="meetingTime">Meeting Time:</label>
                <input id="meetingTime" v-model="meeting_time" type="text" class="form-control"
                  placeholder="Enter Meeting Time in HH:mm" />
              </div>

              <!-- Conditionally render Bus Return Departure Time field -->
              <div class="form-group" v-if="showBusFields">
                <label for="busDepartureTime">Bus Return Departure Time:</label>
                <input id="busDepartureTime" v-model="busDepartureTime" type="text" class="form-control"
                  placeholder="Enter Departure Time in HH:mm" />
              </div>

              <!-- Conditionally render Departure Train Station field -->
              <div class="form-group" v-if="showTrainFields">
                <label for="trainStation">Departure Train Station:</label>
                <select id="trainStation" v-model="selectedTrainStation" class="form-control">
                  <option v-for="location in locations" :value="location">
                    {{ location.attributes.name }}
                  </option>
                </select>
              </div>

              <!-- Conditionally render Train Departure Time field -->
              <div class="form-group" v-if="showTrainFields">
                <label for="trainDepartureTime">Train Departure Time:</label>
                <input id="trainDepartureTime" v-model="trainDepartureTime" type="text" class="form-control"
                  placeholder="Enter Departure Time in HH:mm" />
              </div>

              <!-- Pick-up Location field -->
              <div class="form-group">
                <label for="euroExchange">Euro Exchange:</label>
                <select id="euroExchange" v-model="selectedEuroExchange" class="form-control">
                  <option v-for="location in locations" :value="location">
                    {{ location.attributes.name }}
                  </option>
                </select>
              </div><br>
              <b-button variant="success" class="btn btn-primary btn-block" @click="onSave">Submit</b-button>
              <b-button v-b-toggle.collapse-1 variant="info" class="btn btn-primary btn-block">Read Me</b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <b-card style="background-color: aliceblue;">
                Please enter the time in 24:00 format e.g. 17:45
                </b-card>
              </b-collapse>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: null,
      templates: [],
      locations: [],
      selectedCruiseLine: null,
      selectedTemplate: null,
      cruise_line: null,
      selectedDropOffLocation: null,
      selectedPickUpLocation: null,
      selectedEuroExchange: null,
      selectedUndergroundStation: null,
      busDepartureTime: "",
      meeting_time: "",
      emergency_tc: "",
      selectedTrainStation: null,
      trainDepartureTime: "",
      map: null,
      showMap: false,
      newTemplate: {
        locations: [],
      },
    };
  },
  computed: {
  groupedTemplates() {
    const grouped = {};
    this.templates.forEach(template => {
      const cruiseLineData = template.attributes?.cruise_line?.data;
      if (cruiseLineData) {
        const cruiseLineName = cruiseLineData.attributes?.name;
        if (cruiseLineName) {
          if (!grouped[cruiseLineName]) {
            grouped[cruiseLineName] = [];
          }
          grouped[cruiseLineName].push(template);
        }
      }
    });
    return grouped;
  },
  showBusFields() {
    return this.selectedTemplate && (this.selectedTemplate.attributes.type === 'Bus' || this.selectedTemplate.attributes.type === 'Train and Bus');
  },
  showTrainFields() {
    return this.selectedTemplate && (this.selectedTemplate.attributes.type === 'Train' || this.selectedTemplate.attributes.type === 'Train and Bus');
  }
},

  async mounted() {
    this.getTemplates();
    this.getLocations();
  },
  methods: {
    getTemplates() {
      this.$xhr.get("/api/sk-templates?populate=*")
        .then((result) => {
          console.log(result.data.data, "Template data fetched successfully");
          this.templates = result.data.data;
        })
        .catch((error) => {
          console.error("Error fetching template data:", error);
        });
    },
    getLocations() {
      this.$xhr.get("/api/sk-locations")
        .then((result) => {
          console.log(result.data.data, "Location data fetched successfully");
          this.locations = result.data.data;
        })
        .catch((error) => {
          console.error("Error fetching location data:", error);
        });
    },
    onSave() {
  let formattedBusDepartureTime = this.showBusFields ? this.formatTime(this.busDepartureTime) : null;
  let formattedTrainDepartureTime = this.showTrainFields ? this.formatTime(this.trainDepartureTime) : null;
  const formattedMeetingTime = this.formatTime(this.meeting_time);

  // Handle the case where only the train option is selected
  if (this.showTrainFields && !this.showBusFields) {
    formattedBusDepartureTime = formattedBusDepartureTime || '00:00:00';
  }
  // Handle the case where only the bus option is selected
  if (this.showBusFields && !this.showTrainFields) {
    formattedTrainDepartureTime = formattedTrainDepartureTime || '00:00:00';
  }

  if (formattedMeetingTime === null) {
    console.error('Invalid time format');
    return;
  }

  // Check if selected options are not null before accessing their id properties
  const data = {
    data: {
      busDepartureTime: formattedBusDepartureTime,
      meeting_time: formattedMeetingTime,
      train_departuretime: formattedTrainDepartureTime,
      sk_templates: this.selectedTemplate ? this.selectedTemplate.id : null,
      drop_off_location: this.selectedDropOffLocation ? this.selectedDropOffLocation.id : null,
      pick_up_location: this.selectedPickUpLocation ? this.selectedPickUpLocation.id : null,
      euro_exchange: this.selectedEuroExchange ? this.selectedEuroExchange.id : null,
      underground_station: this.selectedUndergroundStation ? this.selectedUndergroundStation.id : null,
      train_station: this.selectedTrainStation ? this.selectedTrainStation.id : null,
      date: this.date,
      emergency_tc: this.emergency_tc
    },
  };

  console.log('data', data);
  this.$xhr.post("/api/sk-items", { ...data })
    .then((result) => {
      console.log('result', result);
      this.$router.push({ name: 'templates', params: { templateId: this.selectedTemplate.id } });
    })
    .catch((error) => {
      console.error('Error saving data:', error);
    });
},


    formatTime(time) {
      if (time) {
        const parts = time.split(':');
        if (parts.length === 2) {
          return `${parts[0]}:${parts[1]}:00`;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.logo {
  margin: auto;
  display: flex;
  max-width: 330px;
}

.my-4 {
  margin-left: 650px;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #E4953D;
}

.btn-block {
  border-radius: 20px;
}

label {
  font-weight: bold;
}

p.text-center {
  font-size: 12px;
}

.container.register {
  padding: 20px;
  max-width: 100%;
}

.form-group {
  margin-bottom: 0px;
}
</style>

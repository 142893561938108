<template>
  <div class="container register">
    <h2 style="text-align: center;">Edit Location</h2>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="updateLocation">
          <img class="logo mb-4" alt="Vue logo" src="@lib/frontend/assets/new.png">

          <div class="form-group">
            <label for="name">Name:</label>
            <input v-model="editedLocation.name" type="text" id="name" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="latitude">Latitude:</label>
            <input v-model="editedLocation.latitude" type="text" id="latitude" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="longitude">Longitude:</label>
            <input v-model="editedLocation.longitude" type="text" id="longitude" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="address">Address:</label>
            <input v-model="editedLocation.address" type="text" id="address" class="form-control" required />
          </div>

          <button type="submit" class="btn btn-primary">Update</button>
          <router-link :to="{ name: 'Locations' }" class="btn btn-secondary">Back to List</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      editedLocation: {
        name: "",
        latitude: "",
        longitude: "",
        address: "",
      },
    };
  },
  created() {
    const locationId = this.$route.params.id;
    console.log("Received locationId:", locationId);
    this.fetchLocationToEdit(locationId);
  },

  methods: {
    async fetchLocationToEdit(id) {
  const result = await this.$xhr.get(`/api/sk-locations/${id}`);
  this.editedLocation = result.data.data.attributes;
},

async updateLocation() {
  const locationData = { data: this.editedLocation};
  const result = await this.$xhr.put(`/api/sk-locations/${this.$route.params.id}`,
  locationData
  );

  if (result.status === 200) {
    this.$router.push({ name: "Locations" });
  }
}

  },
};
</script>

<style scoped>
.container {
  max-width: 750px;
}

.logo {
  margin: auto;
  display: block;
  max-width: 330px;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #E4953D;
}

.form-group {
  margin-bottom: 15px;
}
</style>
<template>
    <div class="container mt-5">
        <div class="row mb-3 align-items-center">
            <div class="col-md-6">
                <h2 style="text-align: center;">Cruise Lines</h2>
            </div>
            <div class="col-md-6 text-end">
                <button class="btn btn-success" @click="showForm = !showForm">
                    {{ showForm ? 'Close Form' : 'Add Cruise Line' }}
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Logo</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="cruiseLine in cruiseLines" :key="cruiseLine.id">
                            <td>{{ cruiseLine.attributes.name }}</td>
                            <td>
                                <img :src="getFullImageUrl(cruiseLine.attributes.logo.data.attributes.url)"
                                    :alt="cruiseLine.attributes.logo.data.attributes.alternativeText"
                                    class="img-thumbnail" />
                            </td>
                            <td>
                                <button class="btn btn-primary btn-sm me-2"
                                    @click="editCruiseLine(cruiseLine)">Edit</button>
                                <button class="btn btn-danger btn-sm"
                                    @click="deleteCruiseLine(cruiseLine.id)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-md-4" v-if="showForm">
                <div class="card p-3">
                    <h2>{{ isEditing ? 'Edit Cruise Line' : 'Add Cruise Line' }}</h2>
                    <form @submit.prevent="handleSubmit">
                        <div class="mb-3">
                            <label for="name" class="form-label">Name:</label>
                            <input type="text" v-model="form.name" class="form-control" required />
                        </div>
                        <div class="mb-3">
                            <label for="logo" class="form-label">Logo:</label>
                            <div v-if="form.logo">
                                <img :src="form.logo" class="img-thumbnail" />
                            </div>
                            <input type="file" @change="handleFileUpload" class="form-control" />
                        </div>
                        <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Add' }}</button>
                        <button type="button" class="btn btn-secondary" @click="resetForm">Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@lib/frontend/config';

export default {
    data() {
        return {
            cruiseLines: [],
            showForm: false,
            isEditing: false,
            form: {
                id: null,
                name: '',
                logo: null
            }
        };
    },
    created() {
        this.fetchCruiseLines();
    },
    methods: {
        async fetchCruiseLines() {
            const response = await this.$xhr.get("/api/cruise-lines?populate=*");
            this.cruiseLines = response.data.data;
        },
        getFullImageUrl(partialUrl) {
            return config.buildUrl('api', partialUrl);
        },
        handleFileUpload(event) {
            this.form.logo = event.target.files[0];
        },
        async handleSubmit() {
            const formData = new FormData();
            formData.append('data', JSON.stringify({ name: this.form.name }));
            formData.append('files.logo', this.form.logo);

            if (this.isEditing) {
                await this.$xhr.put(`/api/cruise-lines/${this.form.id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                await this.$xhr.post("/api/cruise-lines", formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            this.fetchCruiseLines();
            this.resetForm();
        },
        editCruiseLine(cruiseLine) {
            this.isEditing = true;
            this.showForm = true;
            this.form.id = cruiseLine.id;
            this.form.name = cruiseLine.attributes.name;
            this.form.logo = cruiseLine.attributes.logo.data.attributes.url;
        },
        async deleteCruiseLine(id) {
            if (confirm("Are you sure you want to delete this cruise line?")) {
                await this.$xhr.delete(`/api/cruise-lines/${id}`);
                this.fetchCruiseLines();
            }
        },

        resetForm() {
            this.isEditing = false;
            this.showForm = false;
            this.form.id = null;
            this.form.name = '';
            this.form.logo = null;
        }
    }
};
</script>

<style scoped>
.container {
    max-width: 1800px;
    margin: auto;
}


.img-thumbnail {
    max-width: 100px;
}

.card {
    margin-top: 20px;
}
</style>
<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <b-form-input v-model="searchTerm" placeholder="Search locations..." class="filter"></b-form-input>
      <b-button style="margin-left: 68%" variant="primary" @click="navigateToAddPage">Add Location</b-button>

    </div>


    <!-- Display the list of locations -->
    <div class="table-container">
      <b-table-simple>
        <!-- Table header -->
        <b-thead>
          <b-tr>
            <b-th>Name</b-th>
            <b-th>Latitude</b-th>
            <b-th>Longitude</b-th>
            <b-th>Address</b-th>
            <b-th>Actions</b-th>
          </b-tr>
        </b-thead>
        <!-- Table body -->
        <b-tbody>
          <b-tr v-for="location in filteredLocations" :key="location.id">
            <b-td>{{ location.attributes.name }}</b-td>
            <b-td>{{ location.attributes.latitude }}</b-td>
            <b-td>{{ location.attributes.longitude }}</b-td>
            <b-td>{{ location.attributes.address }}</b-td>
            <b-td>
              <b-button variant="success" @click="navigateToEditPage(location.id)">Edit</b-button>
              <b-button variant="danger" @click="deleteLocation(location.id)">Delete</b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locations: [],
      showForm: false,
      searchTerm: '',

      post: {
        data: {
          name: "",
          latitude: "",
          longitude: "",
          address: "",
          emergency_contactUS: "",
        },
      },
      editingLocationId: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    filteredLocations() {
      if (!this.searchTerm) {
        return this.locations;
      }
      const searchTermLower = this.searchTerm.toLowerCase();
      return this.locations.filter(location => {
        return (
          location.attributes.name.toLowerCase().includes(searchTermLower) ||
          location.attributes.latitude.toString().includes(searchTermLower) ||
          location.attributes.longitude.toString().includes(searchTermLower) ||
          location.attributes.address.toLowerCase().includes(searchTermLower)
        );
      });
    }
  },
  methods: {
    async getData() {
  const result = await this.$xhr.get("/api/sk-locations");
  this.locations = result.data.data;
},

  
    navigateToEditPage(locationId) {
  this.$router.push({ name: 'Location-Edit', params: { id: locationId } });
},

    navigateToAddPage() {
      this.$router.push({ name: 'Location-Add' });
    },
    async deleteLocation(id) {
  const result = await this.$xhr.delete(`/api/sk-locations/${id}`);
  if (result.status === 200) {
    this.locations = this.locations.filter((location) => location.id !== id);
  }
},
  },
};
</script>


<style scoped>
.table-container {
  font-size: 12px;
}
</style>
<template>
  <header>
    <template v-if="profile || $route.meta.navbar !== false">
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/">Time Change</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <b-navbar-nav v-if="profile && profile.role && profile.role.name === 'Authenticated'">
            <b-nav-item to="/shipcalls">Ship Calls</b-nav-item>
            <b-nav-item to="/register">Register a Template</b-nav-item>
            <b-nav-item to="/locations">Locations</b-nav-item>
            <b-nav-item to="/restaurant">Restaurants</b-nav-item>
            <b-nav-item to="/template">Templates</b-nav-item>
            <b-nav-item to="/cruiselines">Cruiselines</b-nav-item>
            <b-nav-item to="/convert">Convert</b-nav-item>
            <b-nav-item to="/msc">MSC</b-nav-item>
            <b-nav-item to="/cunard">Cunard</b-nav-item>
            <b-nav-item to="/oceania">Oceania</b-nav-item>
            <b-nav-item to="/collection">Collection</b-nav-item>



          </b-navbar-nav>
          <b-navbar-nav v-if="profile && profile.role && profile.role.name === 'Msc'">

            <b-nav-item to="/msc">MSC</b-nav-item>


          </b-navbar-nav>
          <b-navbar-nav v-if="profile && profile.role && profile.role.name === 'Cunard'">

            <b-nav-item to="/cunard">Cunard</b-nav-item>


          </b-navbar-nav>
          <b-navbar-nav v-if="profile && profile.role && profile.role.name === 'Oceania'">

            <b-nav-item to="/oceania">Oceania</b-nav-item>


          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="profile" @click="logout">Logout ({{ profile.email }})</b-nav-item>
            <b-nav-item v-else :to="{ name: 'login' }">Login</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </template>
  </header>
</template>

<script setup>
import { inject } from 'vue'
const { profile, logout } = inject('user')
</script>

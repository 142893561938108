<template>
    <div class="container register">
        <h2 style="text-align: center;">{{ editingTemplateId ? 'Edit Template' : 'Add Template' }}</h2>
        <div class="card">
            <div class="card-body">
                <img class="logo mb-4" alt="Vue logo" src="@lib/frontend/assets/new.png">
                <form class="form">
                    <div class="form-group">
                        <label for="tour_name">Tour Name</label>
                        <input v-model="post.data.tour_name" type="text" id="tour_name" class="form-control" required />
                    </div>
                    <div class="form-group">
                        <label for="cruise_line">Cruise Line</label>
                        <select v-model="post.data.cruise_line" id="cruise_line" class="form-control" required>
                            <option v-for="cruiseLine in cruiseLines" :key="cruiseLine.id" :value="cruiseLine.id">
                                {{ cruiseLine.attributes.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="city">Visiting City</label>
                        <input v-model="post.data.city" type="text" id="city" class="form-control" required />
                    </div>

                    <div class="form-group">
                        <label for="end_city">End City</label>
                        <input v-model="post.data.end_city" type="text" id="end_city" class="form-control" required />
                    </div>

                    <div class="form-group">
                        <label for="type">Type (Train/Bus)</label>
                        <select v-model="post.data.type" id="type" class="form-control" required>
                            <option v-for="type in possibleTypes" :key="type" :value="type">{{ type }}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="address_tc">Time Change Address</label>
                        <input v-model="post.data.address_tc" type="text" id="address_tc" class="form-control" required />
                    </div>
                    <!-- <div class="form-group">
                        <label for="emergency_phonenumber">Emergency Phone Number</label>
                        <input v-model="post.data.emergency_phonenumber" type="text" id="emergency_phonenumber"
                            class="form-control" required />
                    </div> -->

                    <div class="form-group">
                        <label for="emergency_contactUS">American Embassy Contact</label>
                        <input v-model="post.data.emergency_contactUS" type="text" id="emergency_contactUS"
                            class="form-control" required />
                    </div>
                    <div class="form-group">
                        <label for="emergency_contactUSconsulate">American Consulate Contact</label>
                        <input v-model="post.data.emergency_contactUSconsulate" type="text" id="emergency_contactUSconsulate"
                            class="form-control" required />
                    </div>
                    
                    <div class="form-group">
                        <label for="address_US">American Embassy Address</label>
                        <input v-model="post.data.address_US" type="text" id="address_US" class="form-control" required />
                    </div>
                    
                    <div class="form-group">
                        <label for="address_USconsulate">American Consulate Address</label>
                        <input v-model="post.data.address_USconsulate" type="text" id="address_USconsulate" class="form-control" required />
                    </div>                    
                    <div class="form-group">
                        <label for="emergency_contactUK">British Embassy Contact</label>
                        <input v-model="post.data.emergency_contactUK" type="text" id="emergency_contactUK"
                            class="form-control" required />
                    </div>
                    <div class="form-group">
                        <label for="address_UK">British Embassy Address</label>
                        <input v-model="post.data.address_UK" type="text" id="address_UK" class="form-control" required />
                    </div>
                    <button v-if="editingTemplateId" @click="updateTemplate" class="form-control">Update</button>
                    <button v-else @click="addTemplate" class="form-control">Add</button>
                </form>
            </div>
        </div>
    </div>
</template>


  
<script>
export default {
    data() {
        return {
            post: {
                data: {
                    tour_name: "",
                    cruise_line: null,
                    city: "",
                    end_city: "",
                    type: "",
                    address_tc: "",
                    // emergency_phonenumber: "",
                    emergency_contactUS: "",
                    address_US: "",
                    emergency_contactUSconsulate: "",
                    address_USconsulate: "",
                    emergency_contactUK: "",
                    address_UK: ""
                },
            },
            editingTemplateId: null,
            cruiseLines: [], // Added to store available cruise line options
            possibleTypes: ["Train", "Bus", "Train and Bus"],
        };
    },

    created() {
        const id = this.$route.params.id;
        if (id) {
            this.editingTemplateId = id;
            this.fetchTemplateById(id);
        }
        this.fetchCruiseLines();
    },

    methods: {
        async fetchCruiseLines() {
            const response = await this.$xhr.get("/api/cruise-lines");
            this.cruiseLines = response.data.data;
        },

        async addTemplate() {
            const response = await this.$xhr.post("/api/sk-templates", this.post);
            if (response.status === 200) {
                this.$router.push({ name: 'Templates' });
            }
        },
        async updateTemplate() {
            const response = await this.$xhr.put(`/api/sk-templates/${this.editingTemplateId}`, this.post);
            if (response.status === 200) {
                this.$router.push({ name: 'Templates' });
            }
        },
        async fetchTemplateById(id) {
            const response = await this.$xhr.get(`/api/sk-templates/${id}`);
            const responseData = response.data.data;
            this.post.data.tour_name = responseData.attributes.tour_name;

            if (responseData.attributes.cruise_line) {
                this.post.data.cruise_line = responseData.attributes.cruise_line.id;
            } else {
                this.post.data.cruise_line = null;
            }

            this.post.data.city = responseData.attributes.city;
            this.post.data.end_city = responseData.attributes.end_city;
            this.post.data.type = responseData.attributes.type;
            this.post.data.address_tc = responseData.attributes.address_tc;
            // this.post.data.emergency_phonenumber = responseData.attributes.emergency_phonenumber;
            this.post.data.address_US = responseData.attributes.address_US;
            this.post.data.address_USconsulate = responseData.attributes.address_USconsulate;
            this.post.data.emergency_contactUS = responseData.attributes.emergency_contactUS;
            this.post.data.emergency_contactUSconsulate = responseData.attributes.emergency_contactUSconsulate;
            this.post.data.address_UK = responseData.attributes.address_UK;
            this.post.data.emergency_contactUK = responseData.attributes.emergency_contactUK;
        },
    },
};
</script>

  
<style scoped>
.container {
    max-width: 1000px;
    
}


.logo {
    margin: auto;
    display: block;
    max-width: 330px;
}

.card {
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: #E4953D;
}

.btn-block {
    border-radius: 20px;
}

label {
    font-weight: bold;
}

.form-group {
    margin-bottom: 15px;
}
</style>
<template>
  <div>
    <b-tabs>
      <b-tab title="Pre Program">
        <Preoceania />
      </b-tab>
      <b-tab title="Post Program">
        <Postoceania/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Preoceania from './Preoceania.vue';
import Postoceania from './Postoceania.vue';

export default {
  components: {
    Preoceania,
    Postoceania
  }
};
</script>
<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.legend-item {
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
.bg-success {
  background-color: #28a745 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-pink {
  background-color: #d36d7e !important;
}

.bg-yellow {
  background-color: #8e8e6a !important;
}

.text-white {
  color: #fff !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.progress {
  position: relative;
  height: 20px;
  margin-top: 5px;
}

.progress-bar {
  transition: width 0.3s ease-in-out;
  background-color: #e6e36a;
  height: 100%;
}

.fa-plane {
  color: #f10f0f;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  position: absolute;
}
</style>

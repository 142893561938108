<template>
  <div class="container register">
    <h2 style="text-align: center;">Edit Restaurant</h2>
    <div class="card">
      <div class="card-body">
        <img class="logo mb-4" alt="Vue logo" src="@lib/frontend/assets/new.png">
        <!-- Form for editing location -->
        <form @submit.prevent="updateRestaurant">
          <!-- Form fields for editing a location -->
          <div class="form-group">
            <label for="name">Name:</label>
            <input v-model="editedRestaurant.name" type="text" id="name" class="form-control" required />
          </div>

          <div class="form-group">
            <label for="openinghours">Opening Hours</label>
            <input v-model="editedRestaurant.opening_hours" type="text" id="name" class="form-control"
              placeholder="Opening hours" required />
          </div>

          <div class="form-group">
            <label for="website">Website</label>
            <input v-model="editedRestaurant.website" type="text" id="name" class="form-control" placeholder="Website"
              required />
          </div>

          <div class="form-group">
            <label for="latitude">Latitude:</label>
            <input v-model="editedRestaurant.latitude" type="text" id="latitude" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="longitude">Longitude:</label>
            <input v-model="editedRestaurant.longitude" type="text" id="longitude" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="address">Address:</label>
            <input v-model="editedRestaurant.address" type="text" id="address" class="form-control" required />
          </div>

          <div class="form-group">
            <label for="locations">Pick Up Location:</label>
            <b-select   class="form-control" v-model="selectedLocations">
              <option v-for="location in locations" :value="location.id">
                {{ location.attributes.name }}
              </option>
            </b-select>
          </div>
          <button type="submit" class="btn btn-primary">Update</button>
          <router-link :to="{ name: 'Restaurant' }" class="btn btn-secondary">Back to List</router-link>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      editedRestaurant: {
        name: "",
        opening_hours: "",
        website: "",
        latitude: "",
        longitude: "",
        address: "",
        locations: [],
      },
      selectedLocations: [],
      locations: [],
    };
  },
  created() {
    const restaurantId = this.$route.params.id;
    this.fetchRestaurantToEdit(restaurantId);
  },
  async mounted() {
    await this.getLocations();
  },

  methods: {
    async fetchRestaurantToEdit(id) {
      const result = await this.$xhr.get(`/api/restaurants/${id}`);
      this.editedRestaurant = result.data.data.attributes;
      if (this.editedRestaurant && this.editedRestaurant.locations) {
        this.selectedLocations = this.editedRestaurant.locations.map(location => location.id);
      }
    },
    async updateRestaurant() {
      const restaurantData = { data: { ...this.editedRestaurant, locations: this.selectedLocations, }, };
      const result = await this.$xhr.put(`/api/restaurants/${this.$route.params.id}`, restaurantData);
      if (result.status === 200) { this.$router.push({ name: "Restaurant" }); }
    },

    async getLocations() {
      const result = await this.$xhr.get("/api/sk-locations");
      this.locations = result.data.data;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 750px;
}

.logo {
  margin: auto;
  display: block;
  max-width: 330px;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #E4953D;
}

.btn-block {
  border-radius: 20px;
}

label {
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}
</style>

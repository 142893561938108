import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

// Define common styles
const createStyle = (fontColor, bgColor, borderColor) => ({
  font: { name: 'Verdana Pro Light', bold: true, color: { argb: fontColor } },
  alignment: { horizontal: 'center' },
  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor } },
  border: {
    bottom: { style: 'thin', color: { argb: borderColor } },
    left: { style: 'thin', color: { argb: borderColor } },
    right: { style: 'thin', color: { argb: borderColor } },
    top: { style: 'thin', color: { argb: borderColor } }
  }
});
const createStyleNoBorder = (fontColor, bgColor) => ({
  font: { name: 'Verdana Pro Light', bold: true, color: { argb: fontColor } },
  alignment: { horizontal: 'center' },
  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor } },
});


const titleStyle = createStyle('FF000000', 'FFE2923D', 'FF7F7F7F');
const headerStyle = createStyle('FF000000', 'FFE2923D', 'FF7F7F7F');
const headerStyle1 = createStyle('FF000000', 'FFBFBFBF', 'FF7F7F7F');
const noBorderStyle = createStyleNoBorder('FF000000', 'FFBFBFBF'); // No border style

const tableBorder = {
  top: { style: 'thin', color: { argb: 'FF7F7F7F' } },
  left: { style: 'thin', color: { argb: 'FF7F7F7F' } },
  bottom: { style: 'thin', color: { argb: 'FF7F7F7F' } },
  right: { style: 'thin', color: { argb: 'FF7F7F7F' } }
};
const estimateRowHeight = (text) => {
  const lineHeight = 10; // Adjust as needed
  const maxLineLength = 50; // Maximum number of characters per line
  const numberOfLines = Math.ceil(text.length / maxLineLength);
  return lineHeight * numberOfLines;
};

export async function exportToExcel(data, fileName = 'Embarkation_Data.xlsx') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Flights');

  // Add title and merge cells
  worksheet.mergeCells('A1:J1');
  worksheet.getCell('A1').value = 'EMBARKATION FLIGHT REPORT';
  worksheet.getCell('A1').style = titleStyle;
  worksheet.getCell('A1').border = noBorderStyle;

  const headers = ['FLIGHT', 'DEP', 'PAX', 'OWN AIR', 'ETA', 'ATA', 'TERMINAL', 'TRF PREVIEW', 'ASS. REQ', 'REMARKS'];
  worksheet.getRow(5).values = headers;
  worksheet.getRow(5).eachCell(cell => cell.style = headerStyle);

  // Add data starting from row 6
  let rowNumber = 6;
  data.forEach(item => {
    const { FLIGHT = '', DEP = '', PAX = 0, OWNAIR = 0, ETA = '', ATA = '', arrivalTerminal = '', TRF_PREVIEW = '', Assistant = '', Remarks = '' } = item;
    worksheet.addRow([FLIGHT, DEP, PAX, OWNAIR, ETA, ATA, arrivalTerminal, TRF_PREVIEW, Assistant, Remarks]);
    rowNumber++;
  });

  

// Set the value for I6Value based on the ARRIVAL date in the data
const I6Value = data[0]?.ARRIVAL ? moment(data[0].ARRIVAL).format('YYYY-MM-DD') : '';

// Set values in specific cells
worksheet.getCell('B3').value = 'Date';
worksheet.getCell('D3').value = I6Value;
worksheet.getCell('G3').value = 'Ship';
worksheet.getCell('I3').value = 'Port';

// Apply the header style to the range A3:J3
worksheet.getCell('A3').style = headerStyle1;
worksheet.getCell('B3').style = headerStyle1;
worksheet.getCell('C3').style = headerStyle1;
worksheet.getCell('D3').style = headerStyle1;
worksheet.getCell('E3').style = headerStyle1;
worksheet.getCell('F3').style = headerStyle1;
worksheet.getCell('G3').style = headerStyle1;
worksheet.getCell('H3').style = headerStyle1;
worksheet.getCell('I3').style = headerStyle1;
worksheet.getCell('J3').style = headerStyle1;

// Apply the border style to the range A3:J3
worksheet.getCell('A3').border = noBorderStyle;
worksheet.getCell('B3').border = noBorderStyle;
worksheet.getCell('C3').border = noBorderStyle;
worksheet.getCell('D3').border = noBorderStyle;
worksheet.getCell('E3').border = noBorderStyle;
worksheet.getCell('F3').border = noBorderStyle;
worksheet.getCell('G3').border = noBorderStyle;
worksheet.getCell('H3').border = noBorderStyle;
worksheet.getCell('I3').border = noBorderStyle;
worksheet.getCell('J3').border = noBorderStyle;



// Merging the entire row for A2 and A4
worksheet.mergeCells('A2:J2');
worksheet.mergeCells('A4:J4');





  // Apply borders
  const lastRow = worksheet.lastRow.number;
  for (let row = 5; row <= lastRow; row++) {
    worksheet.getRow(row).eachCell({ includeEmpty: false }, cell => cell.border = tableBorder);
  }

  worksheet.columns.forEach((column, index) => {
    column.width = 10;
    column.hidden = false;
    column.alignment = { horizontal: 'center' };
  });
  // Set specific column widths
  worksheet.getColumn(10).width = 40;
  worksheet.getColumn(9).width = 11;
  worksheet.getColumn(8).width = 15;
  worksheet.getColumn(2).width = 6;
  worksheet.getColumn(3).width = 6;
  worksheet.getColumn(4).width = 10;
  worksheet.getColumn(5).width = 6;
  worksheet.getColumn(6).width = 6;
  worksheet.getColumn(7).width = 12;

  // Calculate the start row for remarks section
  const remarksStartRow = rowNumber + 2;
  worksheet.mergeCells(`A${remarksStartRow - 2}:J${remarksStartRow - 1}`); 


  // Add remarks section title and headers
  worksheet.mergeCells(`A${remarksStartRow}:J${remarksStartRow}`);
  worksheet.getCell(`A${remarksStartRow}`).value = 'REMARKS';
  worksheet.getCell(`A${remarksStartRow}`).style = createStyle('FF000000', 'FFBFBFBF', 'FF7F7F7F');
  worksheet.getCell(`A${remarksStartRow}`).border = noBorderStyle;


  worksheet.getRow(remarksStartRow + 1).values = ['FLIGHT', 'FIRST NAME', 'FIRST NAME', 'LAST NAME', 'LAST NAME', 'BOOKING #', 'BOOKING #', 'ASSISTANCE', 'ASSISTANCE','ASSISTANCE'];
  worksheet.getRow(remarksStartRow + 1).eachCell({ includeEmpty: true }, cell => cell.style = headerStyle);
  
  // Merge columns for headers
  worksheet.mergeCells(`B${remarksStartRow + 1}:C${remarksStartRow + 1}`);
  worksheet.mergeCells(`D${remarksStartRow + 1}:E${remarksStartRow + 1}`);
  worksheet.mergeCells(`F${remarksStartRow + 1}:G${remarksStartRow + 1}`);
  worksheet.mergeCells(`H${remarksStartRow + 1}:J${remarksStartRow + 1}`);

  // Add remarks data
  let remarksRow = remarksStartRow + 2;
  data.forEach(item => {
    const { details = [] } = item;
    details.forEach(detail => {
      if (detail.remarks.trim() !== '') { // Only add non-empty remarks
        const row = worksheet.addRow([detail.flightNumber, detail.firstName, null, detail.lastName, null, detail.bookingNumber, null, detail.remarks, null]);
        // Estimate row height for the Remarks column
        const remarksHeight = estimateRowHeight(detail.remarks);
        worksheet.getRow(remarksRow).height = Math.max(worksheet.getRow(remarksRow).height || 15, remarksHeight);
        worksheet.getRow(remarksRow).eachCell(cell => cell.style = {
          font: { name: 'Verdana Pro Light', color: { argb: 'FF000000' } },
          alignment: { horizontal: 'left', wrapText: true },
          border: tableBorder
        });
        remarksRow++;
      }
    });
  });

  worksheet.getColumn(2).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    if (rowNumber >= remarksStartRow + 2) {
      worksheet.mergeCells(`B${rowNumber}:C${rowNumber}`);
    }
  });
  worksheet.getColumn(4).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    if (rowNumber >= remarksStartRow + 2) {
      worksheet.mergeCells(`D${rowNumber}:E${rowNumber}`);
    }
  });
  worksheet.getColumn(6).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    if (rowNumber >= remarksStartRow + 2) {
      worksheet.mergeCells(`F${rowNumber}:G${rowNumber}`);
    }
  });
  worksheet.getColumn(8).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    if (rowNumber >= remarksStartRow + 2) {
      worksheet.mergeCells(`H${rowNumber}:J${rowNumber}`);
    }
  });
  const additionalInfoStartRow = remarksRow + 2; // Start below the Remarks section
worksheet.mergeCells(`A${additionalInfoStartRow - 2}:J${additionalInfoStartRow - 1}`); 
worksheet.mergeCells(`A${additionalInfoStartRow}:J${additionalInfoStartRow}`);
worksheet.getCell(`A${additionalInfoStartRow}`).value = 'OPERATIONAL DETAILS';
worksheet.getCell(`A${additionalInfoStartRow}`).style = createStyle('FF000000', 'FFBFBFBF', 'FF7F7F7F');
worksheet.getCell(`A${additionalInfoStartRow}`).border = noBorderStyle;


// Define headers for "Additional Info"
worksheet.getRow(additionalInfoStartRow + 1).values = [
  'FLIGHT', 'FIRST NAME', 'FIRST NAME', 'LAST NAME', 'LAST NAME', 'BOOKING #', 'BOOKING #', 'ADDITIONAL INFO', 'ADDITIONAL INFO', 'ADDITIONAL INFO'
];
worksheet.getRow(additionalInfoStartRow + 1).eachCell({ includeEmpty: true }, cell => cell.style = headerStyle);

// Merge columns for headers
worksheet.mergeCells(`B${additionalInfoStartRow + 1}:C${additionalInfoStartRow + 1}`);
worksheet.mergeCells(`D${additionalInfoStartRow + 1}:E${additionalInfoStartRow + 1}`);
worksheet.mergeCells(`F${additionalInfoStartRow + 1}:G${additionalInfoStartRow + 1}`);
worksheet.mergeCells(`H${additionalInfoStartRow + 1}:J${additionalInfoStartRow + 1}`);

// Add "Additional Info" data
let additionalInfoRow = additionalInfoStartRow + 2;
data.forEach(item => {
  const { infos = [] } = item;
  infos.forEach(info => {
    if (info.additionalInfo && info.additionalInfo.trim() !== '') { // Only add non-empty additional info
      worksheet.addRow([
        info.flightNumber, info.firstName, null, info.lastName, null, info.bookingNumber, null, info.additionalInfo, null
      ]);
      worksheet.getRow(additionalInfoRow).eachCell(cell => cell.style = {
        font: { name: 'Verdana Pro Light', color: { argb: 'FF000000' } },
        alignment: { horizontal: 'left' },
        border: tableBorder
      });
      additionalInfoRow++;
    }
  });
});

// Apply merging for data cells
worksheet.getColumn(2).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
  if (rowNumber >= additionalInfoStartRow + 2) {
    worksheet.mergeCells(`B${rowNumber}:C${rowNumber}`);
  }
});
worksheet.getColumn(4).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
  if (rowNumber >= additionalInfoStartRow + 2) {
    worksheet.mergeCells(`D${rowNumber}:E${rowNumber}`);
  }
});
worksheet.getColumn(6).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
  if (rowNumber >= additionalInfoStartRow + 2) {
    worksheet.mergeCells(`F${rowNumber}:G${rowNumber}`);
  }
});
worksheet.getColumn(8).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
  if (rowNumber >= additionalInfoStartRow + 2) {
    worksheet.mergeCells(`H${rowNumber}:J${rowNumber}`);
  }
});
  // Save the file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), fileName);
}


export function prepareAndExportData(filteredData) {
  if (typeof filteredData !== 'object' || filteredData === null) return;

  const exportData = Object.keys(filteredData).map(key => {
    const dataItem = filteredData[key]?.data || [];
    if (!dataItem.length) return {};

    const group = dataItem[0]?.attributes || {};
    const notes = dataItem.map(item => item.attributes.note || '');
    const countOwnAir = notes.reduce((count, note) => count + (note.match(/OWN AIR/gi) || []).length, 0);

    // Filter remarks that are non-empty
    const remarks = dataItem
      .map(item => ({
        flightNumber: item.attributes.flightNumber || '',
        firstName: item.attributes.firstName || '',
        lastName: item.attributes.guestsLastName || '',
        bookingNumber: item.attributes.bookingNumber || '',
        remarks: item.attributes.remarks || '',
        additionalInfo: item.attributes.additionalInfo || ''
      }))
      .filter(detail => detail.remarks.trim() !== '');

      const additionalInfo = dataItem
      .map(item => ({
        flightNumber: item.attributes.flightNumber || '',
        firstName: item.attributes.firstName || '',
        lastName: item.attributes.guestsLastName || '',
        bookingNumber: item.attributes.bookingNumber || '',
        additionalInfo: item.attributes.additionalInfo || ''
      }))
      .filter(info => info.additionalInfo.trim() !== '');

    const assistantCount = remarks.length;

    return {
      FLIGHT: group.flightNumber || '',
      DEP: group.departureAirport || '',
      'PAX': dataItem.length,
      OWNAIR: countOwnAir,
      ETA: (group.arrivalTime || '').substring(0, 5),
      ATA: group.actualGateArrival ? moment(group.actualGateArrival).format('HH:mm') : '',
      arrivalTerminal: group.arrivalTerminal || '',
      TRF_PREVIEW: group.buses ||'',
      Assistant: assistantCount,
      Remarks: '', // This column is left empty in the main table
      ARRIVAL: group.arrivalDate ? moment(group.arrivalDate).format('YYYY-MM-DD') : '',
      details: remarks, // Add this property to include detailed remarks
      infos: additionalInfo
    };
  }).filter(item => item.FLIGHT);
  const earliestArrival = exportData.reduce((earliest, current) => {
    const currentArrival = current.ARRIVAL ? moment(current.ARRIVAL, 'YYYY-MM-DD') : null;
    if (!earliest || (currentArrival && currentArrival.isBefore(earliest))) {
      return currentArrival;
    }
    return earliest;
  }, null);

  const fileNameDate = earliestArrival ? earliestArrival.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
  const fileName = `${fileNameDate}_Embark.xlsx`;

  exportToExcel(exportData, fileName);
}


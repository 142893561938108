<template>
    <div class="container mt-5">
        <div class="row">
            <div class="col-md-6">
                <!-- Range input for adjusting logo width -->
                <div class="form-group">
                    <label for="logoWidthRange">Logo Width: {{ logoWidth }}</label>
                    <input type="range" id="logoWidthRange" class="form-control-range" v-model="logoWidth"
                        @input="updateLogoDimensions" min="-100" max="100">
                    <input type="number" class="form-control mt-2" v-model="logoWidth" @input="updateLogoDimensions"
                        min="-100" max="100">
                </div>
                <!-- Range input for adjusting logo height -->
                <div class="form-group">
                    <label for="logoHeightRange">Logo Height: {{ logoHeight }}</label>
                    <input type="range" id="logoHeightRange" class="form-control-range" v-model="logoHeight"
                        @input="updateLogoDimensions" min="-100" max="100">
                    <input type="number" class="form-control mt-2" v-model="logoHeight" @input="updateLogoDimensions"
                        min="-100" max="100">
                </div>
                <!-- Range input for adjusting logo position on X-axis -->
                <div class="form-group">
                    <label for="logoXPositionRange">Logo X Position: {{ logoXPosition }}</label>
                    <input type="range" id="logoXPositionRange" class="form-control-range" v-model="logoXPosition"
                        @input="updateLogoPosition" min="-100" max="100">
                    <input type="number" class="form-control mt-2" v-model="logoXPosition" @input="updateLogoPosition"
                        min="-100" max="100">
                </div>
                <!-- Range input for adjusting logo position on Y-axis -->
                <div class="form-group">
                    <label for="logoYPositionRange">Logo Y Position: {{ logoYPosition }}</label>
                    <input type="range" id="logoYPositionRange" class="form-control-range" v-model="logoYPosition"
                        @input="updateLogoPosition" min="-100" max="100">
                    <input type="number" class="form-control mt-2" v-model="logoYPosition" @input="updateLogoPosition"
                        min="-100" max="100">
                </div>
            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
                <!-- Display the logo with adjusted dimensions -->
                <div v-if="selectedTemplate">
                    <img v-if="selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo"
                        :src="getFullImageUrl(selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo.data.attributes.url)"
                        :style="{ width: (logoWidth * 4) + 'px', height: (logoHeight * 4) + 'px', marginTop: logoYPosition + 'px', marginLeft: logoXPosition + 'px', border: '1px solid #ccc', borderRadius: '5px' }"
                        alt="Cruise Line Logo">
                </div>
            </div>
        </div>
        <br><br>
        <h6 class="text-center">Please adjust the width and height of the logo accordingly. The Default width is set to
            60
            and height to 40 and x and y position is set to 0.</h6>
        <div v-if="pdfDataUrl" class="mt-5">
            <embed :src="pdfDataUrl" type="application/pdf"
                style="border: 1px solid #ccc; border-radius: 5px; width: 100%; height: 500px;" />
        </div>

        <div class="row justify-content-end mt-3">
            <div class="col-md-6 text-right">
                <b-button @click="generateAndPreviewPDF" variant="primary">Preview PDF</b-button>
                <b-button @click="downloadPDF" v-if="pdfDataUrl" variant="success">Download PDF</b-button>
            </div>
        </div>
    </div>
</template>


<script>
import config from '@lib/frontend/config';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode-generator';



export default {
    data() {
        return {
            selectedTemplate: null,
            logoWidth: 60, // Default width
            logoHeight: 40, // Default height
            logoXPosition: 0, // Default X position
            logoYPosition: 0, // Default Y position
            pdfDataUrl: null,

        };
    },
    async mounted() {
        const templateId = this.$route.params.id;
        const result = await this.$xhr.get(`/api/sk-items/${templateId}?populate[sk_templates][populate]=cruise_line.logo&populate[drop_off_location][populate]=*&populate[pick_up_location][populate]=*&populate[underground_station][populate]=*&populate[train_station][populate]=*&populate[euro_exchange][populate]=*`);

        if (result.data.data) {
            this.selectedTemplate = result.data.data;
        } else {
            this.selectedTemplate = null;
        }
    },

    methods: {
        updateLogoDimensions() {
            this.logoWidth = Number(this.logoWidth);
            this.logoHeight = Number(this.logoHeight);
        },
        updateLogoPosition() {
            this.logoXPosition = Number(this.logoXPosition);
            this.logoYPosition = Number(this.logoYPosition);
        },
        generateAndPreviewPDF() {
            const pdf = this.generatePDF();
            this.pdfDataUrl = pdf.output('datauristring');
        },
        generatePDF() {
            // Create a new instance of jsPDF
            const pdf = new jsPDF();
            let y = 10;
            // Calculate logo position based on range input
            const logoPositionX = this.logoXPosition;
            const logoPositionY = this.logoYPosition;

            const logoUrl = this.getFullImageUrl(this.selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo.data.attributes.url);
            pdf.addImage(logoUrl, 'JPEG', 70 + logoPositionX, logoPositionY, this.logoWidth, this.logoHeight);

            // y += this.logoHeight + 5;
            y += 35; // Increase y position for next element


            // Set font size and color for the tour name
            const tourNameFontSize = 20; // Initial font size
            pdf.setFontSize(tourNameFontSize);
            pdf.setTextColor(255, 0, 0); // Red color

            // Render the tour name
            const tourNameText = this.selectedTemplate.attributes.sk_templates.data.attributes.tour_name;
            const tourNameWidth = pdf.getTextWidth(tourNameText); // Get the width of the tour name string
            const tourNamePositionX = (pdf.internal.pageSize.getWidth() - tourNameWidth) / 2; // Calculate the x position to center the text
            const tourNamePositionY = y + 20; // Initial position
            pdf.text(tourNameText, tourNamePositionX, tourNamePositionY);

            // Calculate the height of the tour name text
            const tourNameHeight = pdf.getTextDimensions(tourNameText).h;

            y += 30; // Increase y position for next element

            // Reset font size and color for other elements
            pdf.setFontSize(18); // Reset font size
            const welcomeText = `Welcome to ${this.selectedTemplate.attributes.sk_templates.data.attributes.city}!!!`;
            const welcomeTextWidth = pdf.getTextWidth(welcomeText);
            const welcomeTextPositionX = (pdf.internal.pageSize.getWidth() - welcomeTextWidth) / 2; // Calculate the x position to center the text
            const welcomeTextPositionY = tourNamePositionY + tourNameHeight + 10; // Align with the middle of the tour name text
            pdf.text(welcomeText, welcomeTextPositionX, welcomeTextPositionY);
            y += 20; // Increase y position for next element


            // Add introductory text in a box
            pdf.setFontSize(11);
            pdf.setTextColor(0); // Reset color
            const introText = "We hope that today will be an unforgettable experience that you will never forget. Please scan this QR Code which will lead you to a page, you will find very important and useful information about your tour.";
            const introLines = pdf.splitTextToSize(introText, 180); // Adjust width as needed
            pdf.setFontSize(11);
            pdf.text(introLines, 15, y + 5);
            y += (introLines.length * 6) + 1; // Increase y position for next element
            y += 10;
            // Set up initial coordinates for positioning elements
            const qrCodeUrl = this.getQRCodeDataUrl(this.selectedTemplate);
            pdf.addImage(qrCodeUrl, 'JPEG', 55, y + 20, 100, 100);
            return pdf;
        },
        downloadPDF() {
            const pdf = this.generatePDF();
            pdf.save('SurvivalKit.pdf');
        },
        getFullImageUrl(partialUrl) {
            return config.buildUrl('api', partialUrl)
        },
        formatTime(time) {
            const [hours, minutes] = time.split(':').map(Number);
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        },
        getQRCodeDataUrl(template) {
            if (template) {
                const qrCodeDataUrl = this.generateQRCode(config.buildUrl('frontend', `/#/clientpage/${template.id}`));
                return qrCodeDataUrl;
            }
            return '';
        },
        generateQRCode(url) {
            const qr = QRCode(0, 'H'); // Highest error correction level (H)
            qr.addData(url);
            qr.make();

            // Increase the size of the QR code
            const qrCodeDataUrl = qr.createDataURL(10); // Adjust the size (10 means larger)

            return qrCodeDataUrl;
        },

    },
};
</script>
import { ref } from 'vue'
import xhr from "@lib/frontend/xhr"

const profile = ref(null)
const jwt = ref(null)

const loginPw = async (username, password) => {
  const response = await xhr.post('/api/auth/local', {
    identifier: username,
    password: password,
  })
  jwt.value = response.data.jwt
  localStorage.setItem('jwt', jwt.value)
  return loginJwt(jwt.value)
}

const loginJwt = async (token) => {
  try {
    jwt.value = token
    const response = await xhr.get('/api/users/me?populate=role')
    profile.value = response.data
    localStorage.setItem('jwt', jwt.value)
  } catch(e){
    jwt.value = null
    profile.value = null
    throw e
  }
}

const logout = async () => {
  localStorage.clear()
  window.location = '/'
}

export const useUser = () => {
  return {
    profile,
    jwt,
    loginPw,
    loginJwt,
    logout
  }
}

import axios from 'axios'
import config from './config'
import * as composable from './composable'

const user = composable.useUser()

// define axios instance for api requests
const xhr = axios.create({
  baseURL: config.baseUrl.api,
})

// add the jwt to each request if present in config
xhr.interceptors.request.use(
  function (req) {
    console.log("XHR: " + req.url)
    if(user.jwt.value){
      req.headers['Authorization'] = 'Bearer ' + user.jwt.value
    }
    return req
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default xhr
<template>
  <div class="container register">
    <h2 style="text-align: center;">Add Location</h2>
    <div class="card">
      <div class="card-body">
        <img class="logo mb-4" alt="Vue logo" src="@lib/frontend/assets/new.png">
        <form class="form">
          <!-- Form fields for creating a location -->
          <div class="form-group">
            <label for="name">Name:</label>
            <input v-model="post.data.name" type="text" id="name" class="form-control" placeholder="Name" required />
          </div>
          <div class="form-group">
            <label for="latitude">Latitude:</label>
            <input v-model="post.data.latitude" type="text" id="latitude" class="form-control" placeholder="Latitude"
              required />
          </div>
          <div class="form-group">
            <label for="longitude">Longitude:</label>
            <input v-model="post.data.longitude" type="text" id="longitude" class="form-control" placeholder="Longitude"
              required />
          </div>
          <div class="form-group">
            <label for="address">Address:</label>
            <input v-model="post.data.address" type="text" id="address" class="form-control" placeholder="Address"
              required />
          </div>
          <button type="button" @click="addLocation" class="btn btn-primary btn-block">Add</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locations: [],
      post: {
        data: {
          name: "",
          latitude: "",
          longitude: "",
          address: "",
          emergency_contactUS: "",
        },
      },
    };
  },
  methods: {
    async addLocation() {
      const result = await this.$xhr.post("/api/sk-locations", this.post);
      if (result.status === 200) {
        this.$router.push({ name: "Locations" });
      }
    }

  },
};
</script>

<style scoped>
.container {
  max-width: 750px;
}

.logo {
  margin: auto;
  display: block;
  max-width: 330px;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #E4953D;
}

.btn-block {
  border-radius: 20px;
}

label {
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}</style>

<template>
    <div class="home">
      <div class="content">
        <img class="logo" alt="Vue logo" src="@lib/frontend/assets/new.png" style="width: 300px; height: auto;">
        <h1>Welcome to Time Change World</h1>
      </div>
    </div>
  </template>
  
  <style>
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  
  .content {
    text-align: center;
  }
  </style>

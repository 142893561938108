<template>
  <div class="container register">
    <h2 style="text-align: center;">Add Restaurant</h2>
    <div class="card">
      <div class="card-body">
        <img class="logo mb-4" alt="Vue logo" src="@lib/frontend/assets/new.png">
        <form class="form">
          <!-- Form fields for creating a location -->
          <div class="form-group">
            <label for="name">Name:</label>
            <input v-model="post.data.name" type="text" id="name" class="form-control" placeholder="Name" required />
          </div>
          <div class="form-group">
            <label for="openinghours">Opening Hours</label>
            <input v-model="post.data.opening_hours" type="text" id="name" class="form-control"
              placeholder="Opening hours" required />
          </div>
          <div class="form-group">
            <label for="website">Website</label>
            <input v-model="post.data.website" type="text" id="name" class="form-control" placeholder="Website"
              required />
          </div>
          <div class="form-group">
            <label for="latitude">Latitude:</label>
            <input v-model="post.data.latitude" type="text" id="latitude" class="form-control" placeholder="Latitude"
              required />
          </div>
          <div class="form-group">
            <label for="longitude">Longitude:</label>
            <input v-model="post.data.longitude" type="text" id="longitude" class="form-control" placeholder="Longitude"
              required />
          </div>
          <div class="form-group">
            <label for="address">Address:</label>
            <input v-model="post.data.address" type="text" id="address" class="form-control" placeholder="Address"
              required />
          </div>

          <div class="form-group">
            <label for="pickUpLocation">Pick-up Location:</label>

            <select id="locations" v-model="selectedLocationIds" class="form-control">
              <option v-for="location in locations" :value="location.id">{{ location.attributes.name }}</option>
            </select>
          </div>

          <button type="button" @click="addRestaurant" class="btn btn-primary">Add</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      locations: [],
      post: {
        data: {
          name: "",
          opening_hours: "",
          website: "",
          latitude: "",
          longitude: "",
          address: "",
          locations: [],
        },
      },
      selectedLocationIds: [],
    };
  },
  async mounted() {
    this.getLocations();
  },

  methods: {
    async addRestaurant() {
      this.post.data.locations = this.selectedLocationIds;

      const result = await this.$xhr.post("/api/restaurants", this.post);
      if (result.status === 200) {
        this.$router.push({ name: "Restaurant" });
      }
    },
    async getLocations() {
      const result = await this.$xhr.get("/api/sk-locations");
      this.locations = result.data.data;
    },
  },
};
</script>



<style scoped>
.container {
  max-width: 750px;
}

.logo {
  margin: auto;
  display: block;
  max-width: 330px;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #E4953D;
}

.btn-block {
  border-radius: 20px;
}

label {
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}
</style>





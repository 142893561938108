import _ from 'lodash'
import qs from "qs"

export default {
  env: process.env.NODE_ENV,
  baseUrl: {
    frontend: process.env.VUE_APP_BASE_URL || 'http://localhost:8080/',
    api: process.env.VUE_APP_BASE_URL || 'http://localhost:1337/',
  },
  buildUrl(base, path, query){
    const url = new URL(path || '', this.baseUrl[base] || base)
    if(query){
      if(_.isObject(query)) {
        query = qs.stringify(query, { encode: false })
      }
      url.search = query
    }
    return url.toString()
  },
}
<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <b-form-input v-model="searchTerm" placeholder="Search Restaurant..." class="filter"></b-form-input>
      <b-button style="margin-left: 68%" variant="primary" @click="navigateToAddPage">Add Restaurant</b-button>
    </div>
    <div class="table-container">
      <b-table-simple>
        <!-- Table header -->
        <b-thead>
          <b-tr>
            <b-th>Name</b-th>
            <b-th>Opening Hours</b-th>
            <b-th>Website</b-th>
            <b-th>Latitude</b-th>
            <b-th>Longitude</b-th>
            <b-th>Address</b-th>
            <b-th>Pick Up Location</b-th>
            <b-th>Actions</b-th>
          </b-tr>
        </b-thead>
        <!-- Table body -->
        <b-tbody>
          <b-tr v-for="restaurant in filteredRestaurants" :key="restaurant.id">
            <b-td>{{ restaurant.attributes.name }}</b-td>
            <b-td>{{ restaurant.attributes.opening_hours }}</b-td>
            <b-td>
              <a :href="restaurant.attributes.website" target="_blank">{{ restaurant.attributes.name }}</a>
            </b-td>
            <b-td>{{ restaurant.attributes.latitude }}</b-td>
            <b-td>{{ restaurant.attributes.longitude }}</b-td>
            <b-td>{{ restaurant.attributes.address }}</b-td>
            <b-td>
              <div v-for="location in restaurant.attributes.locations.data" :key="location.id">
                {{ location && location.attributes && location.attributes.name ? location.attributes.name : 'N/A' }}
              </div>
            </b-td>
            <b-td>
              <b-button variant="success" @click="navigateToEditPage(restaurant.id)">Edit</b-button>
              <b-button variant="danger" @click="deleteRestaurant(restaurant.id)">Delete</b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      restaurants: [],
      showForm: false,
      searchTerm: '',
      locations: [],
    };
  },
  computed: {
    filteredRestaurants() {
      if (!this.searchTerm) {
        return this.restaurants;
      }
      const searchTermLower = this.searchTerm.toLowerCase();
      return this.restaurants.filter(restaurant => {
        const pickupLocationMatch = restaurant.attributes.locations.data.some(location =>
          location.attributes.name.toLowerCase().includes(searchTermLower)
        );

        return (
          restaurant.attributes.name.toLowerCase().includes(searchTermLower) ||
          restaurant.attributes.latitude.toString().includes(searchTermLower) ||
          restaurant.attributes.longitude.toString().includes(searchTermLower) ||
          restaurant.attributes.opening_hours.toLowerCase().includes(searchTermLower) ||
          restaurant.attributes.website.toLowerCase().includes(searchTermLower) ||
          restaurant.attributes.address.toLowerCase().includes(searchTermLower) ||
          pickupLocationMatch
        );
      });
    }
  },
  async mounted() {
    await this.getData();
    await this.getLocations(); // Fetch locations when the component is mounted
  },
  methods: {
    async getData() {
      const result = await this.$xhr.get("/api/restaurants?populate=*");
      this.restaurants = result.data.data;
    },
    async getLocations() {
      const result = await this.$xhr.get("/api/sk-locations");
      this.locations = result.data.data;
    },

    async deleteRestaurant(id) {
  const confirmation = confirm('Are you sure you want to delete this restaurant?');
  if (!confirmation) {
    return;
  }

  this.$xhr.delete(`/api/restaurants/${id}`)
    .then((result) => {
      if (result.status === 200) {
        this.restaurants = this.restaurants.filter((restaurant) => restaurant.id !== id);
        alert('Restaurant deleted successfully.');
      } else {
        alert('Failed to delete the restaurant. Please try again.');
      }
    })
    .catch((error) => {
      console.error('An error occurred:', error);
      alert('An error occurred while trying to delete the restaurant. Please try again.');
    });
},

    navigateToAddPage() {
      this.$router.push({ name: 'Restaurant-Add' });
    },
    navigateToEditPage(restaurantId) {
      this.$router.push({ name: 'Restaurant-Edit', params: { id: restaurantId } });
    },
  },
};
</script>

<style scoped>
.table-container {
  font-size: 12px;
}
</style>

<template>
  <div class="container mt-5 d-flex flex-column align-items-center">
    <!-- Display the logo -->
    <div v-if="selectedTemplate" class="mb-3">
      <img v-if="selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo"
        :src="getFullImageUrl(selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo.data.attributes.url)"
        style="width: 240px; height: 160px; border: 1px solid #ccc; border-radius: 5px;" alt="Cruise Line Logo">
    </div>

    <h6 class="text-center mb-3">Please click on download button.</h6>
    <b-button @click="downloadPDF" variant="success">Download PDF</b-button>
  </div>
</template>


<script>
import config from '@lib/frontend/config';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode-generator';



export default {
  data() {
    return {
      selectedTemplate: null,
      logoWidth: 60, // Default width
      logoHeight: 40, // Default height
      logoXPosition: 2.5, // Default X position
      logoYPosition: 2.5, // Default Y position
      pdfDataUrl: null,

    };
  },
  async mounted() {
    const templateId = this.$route.params.id;
    const result = await this.$xhr.get(`/api/sk-items/${templateId}?populate[sk_templates][populate]=cruise_line.logo&populate[drop_off_location][populate]=*&populate[pick_up_location][populate]=*&populate[underground_station][populate]=*&populate[train_station][populate]=*&populate[euro_exchange][populate]=*`);

    if (result.data.data) {
      this.selectedTemplate = result.data.data;
    } else {
      this.selectedTemplate = null;
    }
  },

  methods: {

    generatePDF() {
      // Create a new instance of jsPDF
      const pdf = new jsPDF();

      let y = 10; // Define y here
      // Calculate logo position based on range input
      const logoPositionX = this.logoXPosition;
      const logoPositionY = this.logoYPosition;

      const logoUrl = this.getFullImageUrl(this.selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.logo.data.attributes.url);
      pdf.addImage(logoUrl, 'JPEG', 70 + logoPositionX, logoPositionY, this.logoWidth, this.logoHeight);

      // y += this.logoHeight + 5;
      y += 35; // Increase y position for next element


      // Set font size and color for the tour name
      const tourNameFontSize = 20; // Initial font size
      pdf.setFontSize(tourNameFontSize);
      pdf.setTextColor(255, 0, 0); // Red color

      // Render the tour name
      const tourNameText = this.selectedTemplate.attributes.sk_templates.data.attributes.tour_name;
      const tourNameWidth = pdf.getTextWidth(tourNameText); // Get the width of the tour name string
      const tourNamePositionX = (pdf.internal.pageSize.getWidth() - tourNameWidth) / 2; // Calculate the x position to center the text
      const tourNamePositionY = y + 20; // Initial position
      pdf.text(tourNameText, tourNamePositionX, tourNamePositionY);

      // Calculate the height of the tour name text
      const tourNameHeight = pdf.getTextDimensions(tourNameText).h;

      y += 30; // Increase y position for next element

      // Reset font size and color for other elements
      pdf.setFontSize(14); // Reset font size
      const welcomeText = `Welcome to ${this.selectedTemplate.attributes.sk_templates.data.attributes.city}!!!`;
      const welcomeTextWidth = pdf.getTextWidth(welcomeText);
      const welcomeTextPositionX = (pdf.internal.pageSize.getWidth() - welcomeTextWidth) / 2; // Calculate the x position to center the text
      const welcomeTextPositionY = tourNamePositionY + tourNameHeight + 10; // Align with the middle of the tour name text
      pdf.text(welcomeText, welcomeTextPositionX, welcomeTextPositionY);
      y += 20; // Increase y position for next element


           // Add introductory text in a box
           pdf.setFontSize(11);
        pdf.setTextColor(0); // Reset color
        const introText = "We hope that today will be an experience that you will never forget. In order to make it a positive one, we would like to provide you some useful information that gives you necessary details as well as some emergency information. Enjoy your day!!!";
        const introLines = pdf.splitTextToSize(introText, 180); // Adjust width as needed
        pdf.setFontSize(11);
        pdf.text(introLines, 15, y + 5);
        y += (introLines.length * 6) + 1; // Increase y position for next element

        // Add location details in boxes
        pdf.rect(10, y, 190, 55); // Draw a rectangle
        pdf.setFontSize(11);
        pdf.text("Drop-off Location:", 15, y + 10);
        pdf.text(`${this.selectedTemplate.attributes.drop_off_location.data.attributes.name} (${this.selectedTemplate.attributes.drop_off_location.data.attributes.address})`, 75, y + 10);
        let pickUpLocationLabel = this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train' ? 'Meeting Point' : 'Pick-up Location';
        pdf.text(`${pickUpLocationLabel}:`, 15, y + 20);
        pdf.text(`${this.selectedTemplate.attributes.pick_up_location.data.attributes.name} (${this.selectedTemplate.attributes.pick_up_location.data.attributes.address})`, 75, y + 20);

        pdf.text("Euro Exchange Shop:", 15, y + 30);
        pdf.text(`${this.selectedTemplate.attributes.euro_exchange.data.attributes.name} (${this.selectedTemplate.attributes.euro_exchange.data.attributes.address})`, 75, y + 30);
        pdf.text("Nearest Public Train Station:", 15, y + 40);
        pdf.text(`${this.selectedTemplate.attributes.underground_station.data.attributes.name}`, 75, y + 40);
        if (this.selectedTemplate.attributes.sk_templates.data.attributes.type !== 'Bus') {
          pdf.text("Departure Train Station:", 15, y + 50);
          pdf.text(`${this.selectedTemplate.attributes.train_station.data.attributes.name}`, 75, y + 50);
        }
        y += 60; // Increase y position for next element

        // Add meeting time and departure time in boxes
        pdf.setFillColor(255, 255, 205); // Light orange color
        pdf.setTextColor(255, 0, 0); // Red color
        pdf.rect(10, y, 190, 16, 'F'); // Draw a filled rectangle with the specified color
        pdf.setFontSize(14);
        pdf.text("Meeting Time:", 15, y + 10);
        pdf.text(this.formatTime(this.selectedTemplate.attributes.meeting_time), 75, y + 10);
        if (this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train and Bus' || this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Bus') {
          pdf.text("Bus Departure Time:", 105, y + 10);
          pdf.text(this.formatTime(this.selectedTemplate.attributes.busDepartureTime), 160, y + 10);
        } else {
          pdf.text("Train Departure Time:", 105, y + 10);
          pdf.text(this.formatTime(this.selectedTemplate.attributes.train_departuretime), 160, y + 10);
        }
        y += 20; // Increase y position for next element

        pdf.setTextColor(0); // Reset color
        pdf.setFontSize(11);

        // Add emergency contact information in a box
        pdf.rect(10, y, 190, 75); // Draw a rectangle
        pdf.setFontSize(11);
        pdf.text("Police and Ambulance:", 15, y + 10);
        pdf.text("112", 75, y + 10);
        pdf.text("American Embassy Address:", 15, y + 20);
        pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.address_US}`, 75, y + 20);
        pdf.text("American Embassy Contact:", 15, y + 30);
        pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUS}`, 75, y + 30);
        pdf.text("American Consulate Address:", 15, y + 40);
        pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.address_USconsulate}`, 75, y + 40);
        pdf.text("American Consulate Contact:", 15, y + 50);
        pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUSconsulate}`, 75, y + 50);
        pdf.setFontSize(11);
        pdf.text("British Embassy Address:", 15, y + 60);
        pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.address_UK}`, 75, y + 60);
        pdf.text("British Embassy Contact:", 15, y + 70);
        pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.emergency_contactUK}`, 75, y + 70);
      // Add new page for the phrase
      pdf.addPage();
      y = 40; // Reset y position for the second page

      // Add introductory text in a box
      const introText1 = `The ${this.selectedTemplate.attributes.sk_templates.data.attributes.cruise_line.data.attributes.name} Cruise Line agent in ${this.selectedTemplate.attributes.sk_templates.data.attributes.city} is Time Change GmbH. In case of an emergency, please contact:`;
      const introLines1 = pdf.splitTextToSize(introText1, 180); // Adjust width as needed
      pdf.setFontSize(11);
      pdf.text(introLines1, 15, y + 5);
      y += (introLines1.length * 6) + 10; // Increase y position for next element

      pdf.rect(10, y, 190, 30); // Draw a rectangle
      pdf.setFontSize(11);
      pdf.text("Time Change GmbH Address:", 15, y + 10);
      pdf.text(`${this.selectedTemplate.attributes.sk_templates.data.attributes.address_tc}`, 75, y + 10);
      pdf.text("Tour Operator Contact :", 15, y + 20);
      pdf.text(`${this.selectedTemplate.attributes.emergency_tc}`, 75, y + 20);
      y += 40; // Increase y position for next element
      // Add the text before the phrase
      const helpText = "If you should get lost, or if you have any problems and don't know what to do, etc. please show the following phrase to someone (A policeman would be best). It basically asks the person to help you to get to the departure point at the right time.";
      const helpLines = pdf.splitTextToSize(helpText, 180); // Adjust width as needed
      pdf.setFontSize(11);
      pdf.text(helpLines, 15, y + 5);
      y += (helpLines.length * 6) + 1; // Increase y position for next element


      pdf.setFontSize(14); // Bigger font size

      // Add phrase for emergencies
      const phrase = this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train and Bus'
        ? `HALLO, ICH BRAUCHE IHRE HILFE. ICH BIN EIN/E ENGLISCHSPRACHIGER/E TOURIST/IN UND HABE MICH LEIDER VERLAUFEN. UM ${this.formatTime(this.selectedTemplate.attributes.meeting_time)} UHR SOLLTE ICH SPÄTESTENS ${this.selectedTemplate.attributes.pick_up_location.data.attributes.name.toUpperCase()} SEIN, DA ICH DORT MEINEN TRANSFERBUS HABE, DER MICH ANSCHLIEßEND ZU MEINEM ZUG AM ${this.selectedTemplate.attributes.train_station.data.attributes.name.toUpperCase()} (ZUGABFAHRT UM ${this.formatTime(this.selectedTemplate.attributes.train_departuretime)} UHR). ICH WÄRE IHNEN SEHR DANKBAR, WENN SIE MIR WEITERHELFEN KÖNNTEN. DANKE.`
        : this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Bus'
          ? `HALLO, ICH BRAUCHE IHRE HILFE. ICH BIN EIN/E ENGLISCHSPRACHIGER/E TOURIST/IN UND HABE MICH LEIDER VERLAUFEN. UM ${this.formatTime(this.selectedTemplate.attributes.meeting_time)} UHR SOLLTE ICH SPÄTESTENS AM ${this.selectedTemplate.attributes.pick_up_location.data.attributes.name.toUpperCase()} SEIN, DA ICH DORT MEINEN BUS NACH ${this.selectedTemplate.attributes.sk_templates.data.attributes.end_city.toUpperCase()} RECHTZEITIG ERREICHEN MUSS. ICH WÄRE IHNEN SEHR DANKBAR, WENN SIE MIR WEITERHELFEN KÖNNTEN. DANKE.`
          : this.selectedTemplate.attributes.sk_templates.data.attributes.type === 'Train'
            ? `HALLO, ICH BRAUCHE IHRE HILFE. ICH BIN EIN/E ENGLISCHSPRACHIGER/E TOURIST/IN UND HABE MICH LEIDER VERLAUFEN. UM ${this.formatTime(this.selectedTemplate.attributes.meeting_time)} UHR SOLLTE ICH SPÄTESTENS AM ${this.selectedTemplate.attributes.train_station.data.attributes.name.toUpperCase()} SEIN, UM DORT MEINEN ZUG UM ${this.formatTime(this.selectedTemplate.attributes.train_departuretime)} UHR ZU ERREICHEN. ICH WÄRE IHNEN SEHR DANKBAR, WENN SIE MIR WEITERHELFEN KÖNNTEN. DANKE.`
            : ''; // Default case when none of the conditions are met


      const phraseLines = pdf.splitTextToSize(phrase, 180); // Adjust width as needed
      y += 10;
      pdf.rect(10, y, 190, phraseLines.length * 6.5); // Draw a rectangle
      pdf.text(phraseLines, 15, y + 6);


      y += 10;
      pdf.setFontSize(11);

      const helpText1 = "For more details, please scan this QR code. It will direct you to our webpage where you'll find useful information, including restaurants and a map marked with key locations.";
      const helpLines1 = pdf.splitTextToSize(helpText1, 180); // Adjust width as needed
      pdf.text(helpLines1, 15, y + 50);
      y += 10;
      // Set up initial coordinates for positioning elements
      const qrCodeUrl = this.getQRCodeDataUrl(this.selectedTemplate);
      pdf.addImage(qrCodeUrl, 'JPEG', 75, y + 60, 60, 60); // Adjust positioning and size as needed

      // Save the PDF
      return pdf;
    },
    downloadPDF() {
      const pdf = this.generatePDF();
      pdf.save('SurvivalKit.pdf');
    },

    getFullImageUrl(partialUrl) {
      return config.buildUrl('api', partialUrl)
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':').map(Number);
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    },
    getQRCodeDataUrl(template) {
      if (template) {
        const qrCodeDataUrl = this.generateQRCode(config.buildUrl('frontend', `/#/clientpage/${template.id}`));
        return qrCodeDataUrl;
      }
      return '';
    },
    generateQRCode(url) {
      const qr = QRCode(0, 'H'); // Highest error correction level (H)
      qr.addData(url);
      qr.make();

      // Increase the size of the QR code
      const qrCodeDataUrl = qr.createDataURL(10); // Adjust the size (10 means larger)

      return qrCodeDataUrl;
    },

  },
};
</script>
<template>
  <div>
    <h2 style="text-align: center;">Edit Ship Call</h2>
    <b-form @submit.prevent="saveTemplate">
      <b-form-group label="Date">
        <b-form-datepicker v-model="template.attributes.date"></b-form-datepicker>
      </b-form-group>
      <b-form-group label="Drop-off Location">
        <b-form-select v-model="template.attributes.drop_off_location" :options="locationOptions"></b-form-select>
      </b-form-group>
      <b-form-group label="Pick-up Location">
        <b-form-select v-model="template.attributes.pick_up_location" :options="locationOptions"></b-form-select>
      </b-form-group>
      <b-form-group label="Closest Public Train Station">
        <b-form-select v-model="template.attributes.underground_station" :options="locationOptions"></b-form-select>
      </b-form-group>
      <b-form-group label="Meeting Time">
        <b-form-input type="text" v-model="template.attributes.meeting_time"></b-form-input>
      </b-form-group>
      <template v-if="template.type !== 'Train'">
        <b-form-group label="Bus Return Departure Time">
          <b-form-input type="text" v-model="template.attributes.busDepartureTime"></b-form-input>
        </b-form-group>
      </template>
      <template v-if="template.type !== 'Bus'">
        <b-form-group label="Departure Train Station">
          <b-form-select v-model="template.attributes.train_station" :options="locationOptions"></b-form-select>
        </b-form-group>
        <b-form-group label="Train Departure Time">
          <b-form-input type="text" v-model="template.attributes.train_departuretime"></b-form-input>
        </b-form-group>
      </template>
      <b-form-group label="Emergency Contact">
        <b-form-input type="text" v-model="template.attributes.emergency_tc"></b-form-input>
      </b-form-group>
      <b-form-group label="Euro Exchange">
        <b-form-select v-model="template.attributes.euro_exchange" :options="locationOptions"></b-form-select>
      </b-form-group>
      <b-button type="submit" variant="success">Save</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      template: {
        attributes: {
          busDepartureTime: '',
          meeting_time: '',
          train_departuretime: '',
          date: '',
          drop_off_location: '',
          pick_up_location: '',
          underground_station: '',
          train_station: '',
          emergency_tc: '',
          euro_exchange: ''
        },
        type: ''
      },
      locationOptions: []
    };
  },
  created() {
    this.fetchTemplate();
    this.fetchLocations();
  },
  methods: {
  async fetchTemplate() {
    const templateId = this.$route.params.id;
    const response = await this.$xhr.get(`/api/sk-items/${templateId}?populate=*`);
    const data = response.data.data;

    const { attributes } = data;

    this.template.attributes = {
      busDepartureTime: attributes.busDepartureTime,
      meeting_time: attributes.meeting_time,
      train_departuretime: attributes.train_departuretime,
      date: attributes.date,
      emergency_tc: attributes.emergency_tc,
      drop_off_location: attributes.drop_off_location?.data?.id || '',
      pick_up_location: attributes.pick_up_location?.data?.id || '',
      underground_station: attributes.underground_station?.data?.id || '',
      train_station: attributes.train_station?.data?.id || '',
      euro_exchange: attributes.euro_exchange?.data?.id || ''
    };

    this.template.type = attributes.sk_templates.data.attributes.type;
  },
  async fetchLocations() {
    const response = await this.$xhr.get('/api/sk-locations');
    this.locationOptions = response.data.data.map(location => ({
      value: location.id,
      text: location.attributes.name
    }));
  },
  async saveTemplate() {
  // Validate that required fields are not empty
  if (!this.template.attributes.drop_off_location || 
      !this.template.attributes.pick_up_location ||
      !this.template.attributes.underground_station ||
      (this.template.type !== 'Train' && !this.template.attributes.busDepartureTime) ||
      (this.template.type !== 'Bus' && (!this.template.attributes.train_station || !this.template.attributes.train_departuretime))) {
    console.error('Please fill out all required fields.');
    return;
  }
  const { id } = this.$route.params;

  const payloadAttributes = { ...this.template.attributes };
  // If the type is 'Bus', remove train_station and train_departuretime from the payload
  if (this.template.type === 'Bus') {
    delete payloadAttributes.train_station;
    delete payloadAttributes.train_departuretime;
  }

  try {
    await this.$xhr.put(`/api/sk-items/${id}`, { data: payloadAttributes });
    this.$router.push({ name: 'templates' });
  } catch (error) {
    console.error('Error saving template:', error);
  }
}

}

};
</script>


<template>
    <div class="container register">
      <h2 style="text-align: center;">Add Template</h2>
      <div class="card">
        <div class="card-body">
          <img class="logo mb-4" alt="Vue logo" src="@lib/frontend/assets/new.png">
          <form class="form">
            <!-- Form fields for creating a template -->
            <div class="form-group">
              <label for="tour_name">Tour Name</label>
              <input v-model="post.data.tour_name" type="text" id="tour_name" class="form-control" required />
            </div>
            <div class="form-group">
            <label for="cruise_line">Cruise Line</label>
            <select v-model="post.data.cruise_line" id="cruise_line" class="form-control" required>
              <option v-for="template in templates" :key="template.id" :value="template.id">
                {{ template.attributes.name }}
              </option>
            </select>
          </div>
            <div class="form-group">
              <label for="city">Visiting City</label>
              <input v-model="post.data.city" type="text" id="city" class="form-control" required />
            </div>
            <div class="form-group">
              <label for="end_city">End City</label>
              <input v-model="post.data.end_city" type="text" id="end_city" class="form-control" required />
            </div>
            <div class="form-group">
              <label for="type">Type (Train/Bus)</label>
              <select v-model="post.data.type" id="type" class="form-control" required>
                <option v-for="type in possibleTypes" :key="type" :value="type">
                  {{ type }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="address_tc">Time Change Address</label>
              <input v-model="post.data.address_tc" type="text" id="address_tc" class="form-control" required />
            </div>
        
            <div class="form-group">
              <label for="emergency_contactUS">American Embassy Contact</label>
              <input v-model="post.data.emergency_contactUS" type="text" id="emergency_contactUS" class="form-control" required />
            </div>
            <div class="form-group">
              <label for="emergency_contactUSconsulate">American Consulate Contact</label>
              <input v-model="post.data.emergency_contactUSconsulate" type="text" id="emergency_contactUSconsulate" class="form-control" required />
            </div>
            <div class="form-group">
              <label for="address_US">American Embassy Address</label>
              <input v-model="post.data.address_US" type="text" id="address_US" class="form-control" required />
            </div>
            <div class="form-group">
              <label for="address_USconsulate">American Consulate Address</label>
              <input v-model="post.data.address_USconsulate" type="text" id="address_USconsulate" class="form-control" required />
            </div>
            
            <div class="form-group">
              <label for="emergency_contactUK">British Embassy Contact</label>
              <input v-model="post.data.emergency_contactUK" type="text" id="emergency_contactUK" class="form-control" required />
            </div>
            <div class="form-group">
              <label for="address_UK">British Embassy Address</label>
              <input v-model="post.data.address_UK" type="text" id="address_UK" class="form-control" required />
            </div>
            <button type="button" @click="addTemplate" class="btn btn-primary btn-block">Add</button>
          </form>
        </div>
      </div>
    </div>
  </template>
    
  
  <script>
  export default {
    data() {
      return {
        post: {
          data: {
            tour_name: "",
            cruise_line: null,
            city: "",
            end_city: "",
            type: "",
            address_tc: "",
            // emergency_phonenumber: "",
            emergency_contactUS: "",
            address_US: "",
            emergency_contactUSconsulate: "",
            address_USconsulate: "",
            emergency_contactUK: "",
            address_UK: "",
          },
        },
        templates: [],
        possibleTypes: ["Train", "Bus", "Train and Bus"],
      };
    },
    created() {
      this.fetchTemplatesWithCruiseLine();
    },
  
    methods: {
      async fetchTemplatesWithCruiseLine() {
        const response = await this.$xhr.get("/api/cruise-lines?populate=*");
        this.templates = response.data.data;
      },
      async addTemplate() {
        await this.$xhr.post("/api/sk-templates", this.post);
        this.$router.push({ name: 'Templates' });
      },
    },
  };
  </script>
  
<style scoped>
.container {
  max-width: 1000px;
}

.logo {
  margin: auto;
  display: block;
  max-width: 330px;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #E4953D;
}

.btn-block {
  border-radius: 20px;
}

label {
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}
</style>
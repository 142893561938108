<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <b-input-group class="filter">
        <b-form-select v-model="selectedCruiseLine" :options="cruiseLineOptions">
          <option disabled value="null">Cruise Line</option>
        </b-form-select>
        <b-button v-if="selectedCruiseLine" @click="clearCruiseLineFilter" variant="dark" size="sm">
          <span class="mr-2">x</span>
        </b-button>
      </b-input-group>

      <b-input-group class="filter">
        <b-form-select v-model="selectedTourName" :options="tourNameOptions">
          <option disabled value="null">Tour Name</option>
        </b-form-select>
        <b-button v-if="selectedTourName" @click="clearTourNameFilter" variant="dark" size="sm">
          <span class="mr-2">x</span>
        </b-button>
      </b-input-group>

      <b-input-group class="filter">
        <b-form-select v-model="selectedCity" :options="cityOptions">
          <option disabled value="null">Visiting City</option>
        </b-form-select>
        <b-button v-if="selectedCity" @click="clearCityFilter" variant="dark" size="sm">
          <span class="mr-2">x</span>
        </b-button>
      </b-input-group>

      <b-input-group class="filter">
        <b-form-select v-model="selectedEndCity" :options="endCityOptions">
          <option disabled value="null">End City</option>
        </b-form-select>
        <b-button v-if="selectedEndCity" @click="clearEndCityFilter" variant="dark" size="sm">
          <span class="mr-2">x</span>
        </b-button>
      </b-input-group>

      <b-input-group class="filter">
        <b-form-select v-model="selectedType" :options="typeOptions">
          <option disabled value="null">Type</option>
        </b-form-select>
        <b-button v-if="selectedType" @click="clearTypeFilter" variant="dark" size="sm">
          <span class="mr-2">x</span>
        </b-button>
      </b-input-group>
      <b-button style="margin-left: 7%;" variant="primary" @click="navigateToAddPage">Add Template</b-button>

    </div>

    <b-table-simple striped hover :items="filteredTemplates" class="table">
      <b-thead>
        <b-tr>
          <b-th>Tour Name</b-th>
          <b-th>Cruise Line</b-th>
          <b-th>Visiting City</b-th>
          <b-th>End City</b-th>
          <b-th>Type</b-th>
          <b-th>US Embassy Contact</b-th>
          <b-th>UK Embassy Contact</b-th>
          <b-th>Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="{ attributes, id } in filteredTemplates" :key="id">
          <b-td>{{ attributes.tour_name }}</b-td>
          <b-td>{{ attributes.cruise_line.data ? attributes.cruise_line.data.attributes.name : 'N/A' }}</b-td>
          <b-td>{{ attributes.city }}</b-td>
          <b-td>{{ attributes.end_city }}</b-td>
          <b-td>{{ attributes.type }}</b-td>
          <b-td>{{ attributes.emergency_contactUS }}</b-td>
          <b-td>{{ attributes.emergency_contactUK }}</b-td>
          <b-td>
            <b-button variant="success" @click="navigateToEditPage(id)">Edit</b-button>
            <b-button variant="danger" @click="deleteTemplate(id)">Delete</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>


<script>
export default {
  data() {
    return {
      templates: [],
      selectedCruiseLine: null,
      selectedTourName: null,
      selectedCity: null,
      selectedEndCity: null,
      selectedType: null,
      cruiseLineOptions: [],
      tourNameOptions: [],
      cityOptions: [],
      endCityOptions: [],
      typeOptions: [],
    };
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    filteredTemplates() {
      let filtered = this.templates;

      if (this.selectedCruiseLine) {
        filtered = filtered.filter(template =>
          template.attributes.cruise_line.data && template.attributes.cruise_line.data.attributes.name === this.selectedCruiseLine
        );
      }

      if (this.selectedTourName) {
        filtered = filtered.filter(template =>
          template.attributes.tour_name === this.selectedTourName
        );
      }

      if (this.selectedCity) {
        filtered = filtered.filter(template =>
          template.attributes.city === this.selectedCity
        );
      }

      if (this.selectedEndCity) {
        filtered = filtered.filter(template =>
          template.attributes.end_city === this.selectedEndCity
        );
      }

      if (this.selectedType) {
        filtered = filtered.filter(template =>
          template.attributes.type === this.selectedType
        );
      }

      return filtered;
    },
  },
  methods: {
    clearCruiseLineFilter() {
      this.selectedCruiseLine = null;
    },
    clearTourNameFilter() {
      this.selectedTourName = null;
    },
    clearCityFilter() {
      this.selectedCity = null;
    },
    clearEndCityFilter() {
      this.selectedEndCity = null;
    },
    clearTypeFilter() {
      this.selectedType = null;
    },
    async getData() {
      const result = await this.$xhr.get("/api/sk-templates?populate=*");
      this.templates = result.data.data;
      this.cruiseLineOptions = [...new Set(this.templates.map(template => template.attributes.cruise_line.data ? template.attributes.cruise_line.data.attributes.name : 'N/A'))];
      this.tourNameOptions = [...new Set(this.templates.map(template => template.attributes.tour_name))];
      this.cityOptions = [...new Set(this.templates.map(template => template.attributes.city))];
      this.endCityOptions = [...new Set(this.templates.map(template => template.attributes.end_city))];
      this.typeOptions = [...new Set(this.templates.map(template => template.attributes.type))];
    },
    async deleteTemplate(id) {
      const result = await this.$xhr.get(`/api/sk-items?filters[sk_templates][id]=${id}`);
      const associatedItems = result.data.data;

      if (associatedItems.length > 0) {
        alert('This template is in use. Please delete the item first from Ship call.');
        return;
      }
      const confirmed = confirm('Are you sure you want to delete this template?');

      if (confirmed) {
        // Proceed with deletion if confirmed
        const deleteResult = await this.$xhr.delete(`/api/sk-templates/${id}`);
        if (deleteResult.status === 200) {
          this.getData();
        }
      } else {
      }
    },
    navigateToAddPage() {
      this.$router.push({ name: 'Template-Add' });
    },
    navigateToEditPage(id) {
      this.$router.push({ name: 'Edit-Template', params: { id } });
    },
  },
};


</script>
<style>
.table {
  font-size: 12px;
}

.filter {
  max-width: 300px;
  margin-right: 10px;
  margin-top: 10px;
}
</style>

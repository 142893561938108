<template>
  <div class="container mt-5">
    <h2>Create Collection Type</h2>
    <b-form @submit.prevent="createCollectionType">
      <b-form-group label="Select Type" label-for="typeSelect">
        <b-form-select id="typeSelect" v-model="selectedType" :options="typeOptions" required></b-form-select>
      </b-form-group>
      <b-form-group label="Select Date" label-for="datepicker">
        <b-form-datepicker
          id="datepicker"
          v-model="selectedDate"
          required
          placeholder="Choose a date"
        ></b-form-datepicker>
      </b-form-group>
      <b-button type="submit" variant="primary">Create</b-button>
    </b-form>
    <b-alert v-if="alertMessage" :variant="alertVariant" dismissible>
      {{ alertMessage }}
    </b-alert>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  name: 'CreateCollectionType',
  setup() {
    const selectedType = ref(null);
    const selectedDate = ref(null);
    const alertMessage = ref('');
    const alertVariant = ref('success');
    const typeOptions = ref([
      { value: null, text: 'Please select an option' },
      { value: 'embarkation', text: 'Embarkation' },
      { value: 'debarkation', text: 'Debarkation' },
      { value: 'embarkationcunard', text: 'Embarkationcunard' },
      { value: 'debarkationcunard', text: 'Debarkationcunard' },
      { value: 'preoceania', text: 'Preoceania' },
      { value: 'postoceania', text: 'Postoceania' }
    ]);

    const createCollectionType = async () => {
      if (!selectedType.value || !selectedDate.value) {
        alertMessage.value = 'Please select a type and date';
        alertVariant.value = 'danger';
        return;
      }

      // Convert selectedDate to Date object
      const dateObj = new Date(selectedDate.value);
      const date = dateObj.toISOString().split('T')[0]; // Format date to YYYY-MM-DD

      try {
        const response = await axios.post('http://localhost:1337/api/create-collection-type', { type: selectedType.value, date });
        alertMessage.value = response.data.message;
        alertVariant.value = 'success';
      } catch (error) {
        alertMessage.value = `Error creating collection type: ${error.message}`;
        alertVariant.value = 'danger';
      }
    };

    return {
      selectedType,
      selectedDate,
      alertMessage,
      alertVariant,
      typeOptions,
      createCollectionType
    };
  }
};
</script>

<style>
.container {
  max-width: 600px;
}
</style>

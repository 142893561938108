<template>
  <b-card title="Upload Data to API" class="p-4 mx-auto mt-4" style="width: 50%;">
    <div>
      <!-- Date Picker -->
      <b-form-group label="Select Date">
        <b-form-datepicker v-model="selectedDate" placeholder="Select date"></b-form-datepicker>
      </b-form-group>
      
      <input type="file" @change="handleFileUpload" /> <br><br>
      
      <b-row class="mb-3">
        <b-col cols="auto" class="d-flex justify-content-center mb-2">
          <b-button @click="uploadData('embarkation')" variant="primary">Embark MSC</b-button>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-center mb-2">
          <b-button @click="uploadData('debarkation')" variant="primary">Debark MSC</b-button>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-center mb-2">
          <b-button @click="uploadData('embarkationcunard')" variant="primary">Embark Cunard</b-button>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-center mb-2">
          <b-button @click="uploadData('debarkationcunard')" variant="primary">Debark Cunard</b-button>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-center mb-2">
          <b-button @click="uploadData('preoceania')" variant="primary">Pre Oceania   </b-button>
        </b-col>
        <b-col cols="auto" class="d-flex justify-content-center mb-2">
          <b-button @click="uploadData('postoceania')" variant="primary">Post Oceania</b-button>
        </b-col>
      </b-row>
      
      <b-progress v-if="showProgress" :value="uploadProgress" max="100" class="mt-4">
        <b-progress-bar :value="uploadProgress">{{ uploadProgress }}%</b-progress-bar>
      </b-progress>
    </div>
    
    <b-toast v-model="toastVisible" title="Upload Status" variant="success" solid>
      Data uploaded successfully!
    </b-toast>
  </b-card>
</template>


<script>
import * as XLSX from 'xlsx';
import moment from 'moment';

export default {
  data() {
    return {
      excelData: [],
      uploadProgress: 0,
      toastVisible: false,
      showProgress: false,
      selectedDate: null,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);
        
        this.excelData = jsonData;
      };
      
      reader.readAsArrayBuffer(file);
    },
    async uploadData(endpoint) {
      if (!this.selectedDate) {
        alert('Please select a date.');
        return;
      }
      
      if (this.excelData.length === 0) {
        alert('No data to upload.');
        return;
      }

      this.showProgress = true;
      this.uploadProgress = 0;
      const totalRows = this.excelData.length;
      let formattedDate = ''; // Initialize as empty string

      // Format date only for 'embarkation' and 'debarkation' and other if needed
      if (endpoint === 'embarkation' || endpoint === 'debarkation'|| endpoint === 'embarkationcunard' || endpoint === 'debarkationcunard'|| endpoint === 'preoceania'|| endpoint === 'postoceania') {
        formattedDate = moment(this.selectedDate).format('YYYY-MM-DD');
      }

      for (const [index, row] of this.excelData.entries()) {
        const url = formattedDate ? `/api/${endpoint}${formattedDate}s` : `/api/${endpoint}`;
      
        try {
          await this.$xhr.post(url, {
            data: { ...row }
          });
        } catch (error) {
          console.error('Error uploading data:', error);
        }

        this.uploadProgress = Math.round(((index + 1) / totalRows) * 100);
      }

      this.showProgress = false;
      this.toastVisible = true; 
    },
  },
};
</script>

<style scoped>
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
</style>

<template>
 <div>
    <div class="d-flex align-items-center mb-9">
      <b-form-datepicker class="filter" v-model="selectedDate" placeholder="Date"></b-form-datepicker>
      <b-button v-if="selectedDate" @click="clearDateFilter" variant="dark" size="sm">
        <span class="mr-2">x</span>
      </b-button>

      <b-form-select v-model="selectedCruiseLine" :options="cruiseLineOptions" class="filter">
        <option :value="null" disabled>Cruise Line</option>
      </b-form-select>
      <b-button v-if="selectedCruiseLine" @click="clearCruiseLineFilter" variant="dark" size="sm">
        <span class="mr-2">x</span>
      </b-button>

      <b-form-select v-model="selectedTourName" :options="tourNameOptions" class="filter">
        <option :value="null" disabled>Tour Name</option>
      </b-form-select>
      <b-button v-if="selectedTourName" @click="clearTourNameFilter" variant="dark" size="sm">
        <span class="mr-2">x</span>
      </b-button>
    </div>

    <b-table-simple striped hover :items="filteredTemplates" class="table-row-hover">
      <b-thead>
        <b-tr>
          <b-th>Date</b-th>
          <b-th>Tour Name</b-th>
          <b-th>Cruise Line</b-th>
          <b-th>Drop-off Location</b-th>
          <b-th>Pick-up Location</b-th>
          <b-th>Meeting Time</b-th>
          <b-th>Train Departure Time</b-th>
          <b-th>Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="template in filteredTemplates" :key="template.id">
          <b-td @click="navigateToDetails(template.id)">{{ template.attributes.date }}</b-td>
          <b-td @click="navigateToDetails(template.id)">{{ template.attributes.sk_templates.data.attributes.tour_name }}</b-td>
          <b-td @click="navigateToDetails(template.id)">{{ template.attributes.sk_templates.data.attributes.cruise_line.data.attributes.name }}</b-td>
          <b-td @click="navigateToDetails(template.id)">{{ template.attributes.drop_off_location.data.attributes.name }}</b-td>
          <b-td @click="navigateToDetails(template.id)">{{ template.attributes.pick_up_location.data.attributes.name }}</b-td>
          <b-td @click="navigateToDetails(template.id)">{{ formatTime(template.attributes.meeting_time) }}</b-td>
          <b-td @click="navigateToDetails(template.id)">{{ formatTime(template.attributes.train_departuretime) }}</b-td>
          <b-td :responsive="false">
            <router-link :to="{ name: 'qrcode', params: { id: template.id } }" class="btn btn-dark">QR Code</router-link>
            <router-link :to="{ name: 'pdf', params: { id: template.id } }" class="btn btn-success">Pdf</router-link>
            <router-link :to="{ name: 'editlist', params: { id: template.id } }" class="btn btn-warning">Edit</router-link>
            <b-button variant="danger" @click="deleteTemplate(template.id)">Delete</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>


<script>

export default {
  data() {
    return {
      templates: [],
      selectedDate: null,
      selectedCruiseLine: null,
      selectedTourName: null,
      cruiseLineOptions: [],
      tourNameOptions: [],
    };
  },
  computed: {
    filteredTemplates() {
      let filtered = this.templates;

      if (this.selectedDate) {
        const selectedDate = new Date(this.selectedDate);
        filtered = filtered.filter(template => {
          const templateDate = new Date(template.attributes.date);
          return templateDate.toDateString() === selectedDate.toDateString();
        });
      }

      if (this.selectedCruiseLine) {
        filtered = filtered.filter(template => 
          template.attributes.sk_templates.data.attributes.cruise_line.data.attributes.name === this.selectedCruiseLine
        );
      }

      if (this.selectedTourName) {
        filtered = filtered.filter(template => 
          template.attributes.sk_templates.data.attributes.tour_name === this.selectedTourName
        );
      }

      return filtered.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    clearDateFilter() {
      this.selectedDate = null;
    },
    clearCruiseLineFilter() {
      this.selectedCruiseLine = null;
    },
    clearTourNameFilter() {
      this.selectedTourName = null;
    },
    formatTime(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    },
    async getItems() {
      const result = await this.$xhr.get("/api/sk-items?populate[sk_templates][populate]=cruise_line.logo&populate[drop_off_location][populate]=*&populate[pick_up_location][populate]=*&populate[underground_station][populate]=*&populate[train_station][populate]=*&populate[euro_exchange][populate]=*");
      this.templates = result.data.data;
      this.cruiseLineOptions = [...new Set(this.templates.map(template => template.attributes.sk_templates.data.attributes.cruise_line.data.attributes.name))];
      this.tourNameOptions = [...new Set(this.templates.map(template => template.attributes.sk_templates.data.attributes.tour_name))];
    },
    deleteTemplate(id) {
      const confirmed = confirm('Are you sure you want to delete this template?');
      if (confirmed) {
        this.$xhr
          .delete(`/api/sk-items/${id}`)
          .then(() => {
            console.log(`Template with id ${id} deleted successfully`);
            this.templates = this.templates.filter(template => template.id !== id);
          })
          .catch((error) => {
            console.error(`Error deleting template with id ${id}:`, error);
          });
      }
    },
    navigateToDetails(id) {
      this.$router.push({ name: 'clientpage', params: { id } });
    },
  },
};

</script>
<style>
.table-row-hover:hover {
  cursor: pointer;
}

.filter {
  max-width: 300px;
}


</style>

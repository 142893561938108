import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueAxios from 'vue-axios';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import config from "@lib/frontend/config";
import xhr from "@lib/frontend/xhr";
import * as composable from "@lib/frontend/composable";
import '@fortawesome/fontawesome-free/css/all.min.css'

const user = composable.useUser();

console.log("ENV", process.env);
console.log("CONFIG", config);

if (localStorage.getItem('jwt')) {
  try {
    await user.loginJwt(localStorage.getItem('jwt'));
  } catch (e) {
    console.error("Cached login failed, jwt invalid", e);
  }
}

router.beforeEach((to, from, next) => {
  if (to.name === 'login' || to.name === 'home' || to.name === 'clientpage' || to.name === 'clientpdf') {
    return next();
  }
  if (!user.profile.value) {
    console.log("Login required");
    return next({ name: 'login' });
  }
  return next();
});

console.warn = function () { };


// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registered: ', registration);
      })
      .catch(registrationError => {
        console.log('ServiceWorker registration failed: ', registrationError);
      });
  });
}


createApp(App)
  .use(router)
  .use(VueAxios, { $xhr: xhr })
  .use(BootstrapVue)
  .provide('user', user)
  .provide('config', config)
  .mount('#app');
